<template>
  <div id="report-businessCircle" class="report">
    <div class="filtrate">
      <div>
        商圈：
        <el-select v-model="businessCircleId"  clearable placeholder="请选择商圈" @change="refresh">
          <el-option
              v-for="item in businessList"
              :key="item.businessCircleId"
              :label="item.businessCircleName"
              :value="item.businessCircleId">
          </el-option>
        </el-select>
      </div>
      <div>
        <span>停车场类型：</span>
        <el-select v-model="subjectType"  clearable placeholder="请选择停车场类型" @change="refresh">
          <el-option
              v-for="item in select_subjectType"
              :key="item.value"
              :label="item.label"
              :value="item.value">
          </el-option>
        </el-select>
      </div>
    </div>
    <div class="parkingSpaceAmount-parkingSpaceType-commercialVehicles">
      <div class="parkingSpaceAmount statisticalAnalysis">
        <div class="title-filter">
          <h1>商圈车位数量分析</h1>
        </div>
        <parkingSpaceAmount ref="parkingSpaceUpdate" class="chartDetail"/>
      </div>
      <div class="parkingSpaceType statisticalAnalysis">
        <div class="title-filter">
          <h1>车位类型分析</h1>
        </div>
        <parkingSpaceType ref="parkingSpaceType" class="chartDetail"/>
      </div>
      <div class="commercialVehicles statisticalAnalysis">
        <div class="title-filter">
          <h1>商圈在停数量分析</h1>
          <ul class="filter">
            <li>
              <el-select
                  v-model="commercialVehicles.parkingLotId"

                  clearable
                  placeholder="请选择停车场"
                  @change="commercialVehicles_refresh()"
              >
                <el-option
                    v-for="item in parkingLotList"
                    :key="item.parkingLotId"
                    :label="item.parkingLotName"
                    :value="item.parkingLotId"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <commercialVehicles ref="commercialVehicles" class="chartDetail"/>
      </div>
    </div>
    <div class="merchantTurnover-salesRanking">
      <div class="merchantTurnover statisticalAnalysis">
        <div class="title-filter">
          <h1>停车场商户营业逻辑分析</h1>
          <ul class="filter">
            <li>
              <div class="typeSelect">
                <span v-for="item of typeList" :class="{active:type===item}" @click="type=item;merchantTurnover_refresh()">{{
                    item
                  }}</span>
              </div>
            </li>
            <li class="businessTypeList">
              <el-select
                  v-model="businessType"

                  placeholder="请选择类型"
                  @change="merchantTurnover_refresh()"
              >
                <el-option
                    v-for="item in businessTypeList"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select
                  v-model="parkingLotId"

                  clearable
                  placeholder="请选择停车场"
                  @change="merchantTurnover_refresh();issuerId='';getIssuerList()"
              >
                <el-option
                    v-for="item in parkingLotList"
                    :key="item.parkingLotId"
                    :label="item.parkingLotName"
                    :value="item.parkingLotId"
                >
                </el-option>
              </el-select>
            </li>
            <li>
              <el-select
                  v-model="issuerId"

                  clearable
                  placeholder="请选择商户"
                  @change="merchantTurnover_refresh()"
              >
                <el-option
                    v-for="item in issuerList"
                    :key="item.issuerId"
                    :label="item.merchantName"
                    :value="item.issuerId"
                >
                </el-option>
              </el-select>
            </li>
          </ul>
        </div>
        <merchantTurnover ref="merchantTurnover" class="chartDetail"/>
      </div>
      <div class="ranking statisticalAnalysis">
        <ranking ref="ranking"/>
      </div>
    </div>
    <div class="parkingTime statisticalAnalysis">
      <div class="title-filter">
        <h1>停车时长偏好分析</h1>
      </div>
      <parkingTime ref="parkingTime" class="chartDetail"/>
    </div>
  </div>
</template>

<script>

export default {
  components: {
    parkingSpaceAmount: () => import("./parkingSpaceAmount"),
    parkingSpaceType: () => import("./parkingSpaceType"),
    commercialVehicles: () => import("./commercialVehicles"),
    parkingTime: () => import("./parkingTime"),
    merchantTurnover: () => import("./merchantTurnover"),
    ranking: () => import("./ranking")
  },
  data() {
    return {
      businessCircleId: "",
      subjectType: "",
      parkingLotId: "",
      parkingLotList: [],
      issuerId:"",
      issuerList:[],
      businessType:0,
      businessTypeList:[{
        value:0,
        label:"发券"
      },{
        value:1,
        label:"用券"
      }],
      typeList: [
        "今日", "本月", "今年"
      ],
      type: "本月",
      commercialVehicles:{
        parkingLotId:""
      }
    }
  },
  computed:{
    businessList(){
      return this.$store.state.select.businessList;
    }
  },
  created() {
    this.getParkingLotList()
  },
  methods: {
    merchantTurnover_refresh() {
      const params = {
        businessCircleId: this.businessCircleId,
        parkingLotId: this.parkingLotId,
        type: this.type,
        businessType:this.businessType,
        issuerId:this.issuerId
      }
      this.$refs.merchantTurnover.getOptions(params)
      this.$refs.ranking.getRanking(params)
    },
    commercialVehicles_refresh() {
      this.$refs.commercialVehicles.getOptions({
        parkingLotId: this.commercialVehicles.parkingLotId,
      })
    },
    async getParkingLotList(){
      try {
        let res = await this.$http.post("/parking/listAll", {
          businessCircleId: this.businessCircleId
        });
        if (res.code === 0) {
          this.parkingLotList = res.data;
        }else{
          this.$message.error(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    async getIssuerList(){
      try {
        let res = await this.$http.post("/statistics/merchant/list", {
          businessCircleId: this.businessCircleId?this.businessCircleId:null,
          parkingLotId: this.parkingLotId?this.parkingLotId:null,
        });
        if (res.code === 0) {
          this.issuerList = res.data;
        }else{
          this.$message.error(res.msg)
        }
      } catch (err) {
        console.log(err)
      }
    },
    refresh() {
      this.$refs.parkingSpaceType.getOptions({
        businessCircleId: this.businessCircleId
      })
      this.$refs.parkingTime.getList({
        businessCircleId: this.businessCircleId,
        subjectType: this.subjectType ? this.subjectType : null
      })
      this.merchantTurnover_refresh()
      this.getParkingLotList()
    }
  }
}
</script>

<style lang="scss">
#report-businessCircle {
  .filtrate {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 0.63rem;

    & > div {
      margin-right: 1.5rem;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  .businessTypeList{
    width: 100px;
  }

  .parkingSpaceAmount-parkingSpaceType-commercialVehicles {
    display: flex;
    justify-content: space-between;
    height: 20rem;

    .parkingSpaceAmount,.parkingSpaceType,.commercialVehicles {
      width: calc(100% / 3 - 1rem);
    }
  }

  .merchantTurnover-salesRanking {
    display: flex;
    justify-content: space-between;
    height: 30rem;
    margin: 1.5rem 0;

    .merchantTurnover {
      width: calc(65% - 1.5rem);
    }

    .ranking {
      width: 35%;
      padding: 1rem 0.63rem !important;
    }
  }
}
</style>
